import {createContext, forwardRef, useContext} from "react";
import PropTypes from "prop-types";
import MKBox from "components/MKBox";
import MKPaginationItemRoot from "components/MKPagination/MKPaginationItemRoot";

const Context = createContext();

const MKPagination = forwardRef(
    (
        {
            item = false,
            variant = "gradient",
            color = "info",
            size = "medium",
            active = false,
            children,
            placement = "right",
            ...rest
        },
        ref
    ) => {
        // useContext é chamado fora do condicional
        const context = useContext(Context);
        const paginationSize = context && item ? context.size : null;

        let placementValue = "flex-end";
        if (placement === "left") {
            placementValue = "flex-start";
        } else if (placement === "center") {
            placementValue = "center";
        }

        return (
            <Context.Provider value={{variant, color, size}}>
                {item ? (
                    <MKPaginationItemRoot
                        {...rest}
                        ref={ref}
                        variant={active && context ? context.variant : "outlined"}
                        color={active && context ? context.color : "secondary"}
                        iconOnly
                        circular
                        ownerState={{variant, active, paginationSize}}
                    >
                        {children}
                    </MKPaginationItemRoot>
                ) : (
                    <MKBox
                        display="flex"
                        justifyContent={placementValue}
                        alignItems="center"
                        sx={{listStyle: "none"}}
                    >
                        {children}
                    </MKBox>
                )}
            </Context.Provider>
        );
    }
);

// Typechecking props para MKPagination
MKPagination.propTypes = {
    item: PropTypes.bool,
    variant: PropTypes.oneOf(["gradient", "contained"]),
    color: PropTypes.oneOf([
        "white",
        "primary",
        "secondary",
        "info",
        "success",
        "warning",
        "error",
        "light",
        "dark",
    ]),
    size: PropTypes.oneOf(["small", "medium", "large"]),
    active: PropTypes.bool,
    children: PropTypes.node.isRequired,
    placement: PropTypes.oneOf(["left", "right", "center"]),
};

export default MKPagination;
